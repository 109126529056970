.front-h1 {
    @apply text-4xl md:text-5xl lg:text-6xl uppercase text-primary font-bold tracking-wide;
}

.front-h2 {
    @apply text-accent font-condensed text-3xl sm:text-4xl md:text-5xl uppercase font-bold mb-2 tracking-wide pb-2 lg:pb-4;
}

.front-h3 {
    @apply font-mono text-[22px] lg:text-2xl tracking-tight subpixel-antialiased text-primary;
}

.page-h1 {
    @apply text-4xl md:text-6xl uppercase text-primary-dark font-bold tracking-wide;
}

.page-h2 {
    @apply font-mono text-[22px] tracking-tight leading-normal subpixel-antialiased text-primary;
}

.block-h2 {
    @apply page-h2;
}

.block-h3 {
    @apply front-h3;
}

.block-h4 {
    @apply text-primary/80 font-bold lg:text-lg leading-tight;
}

.menu-h3 {
    @apply font-sans text-primary-dark font-bold text-xl leading-tight uppercase tracking-wider;

} 
