/* barlow-regular - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/barlow/barlow-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/barlow/barlow-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/barlow/barlow-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/barlow/barlow-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/barlow/barlow-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/barlow/barlow-v12-latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-700 - latin */
@font-face {
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/barlow/barlow-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/barlow/barlow-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/barlow/barlow-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/barlow/barlow-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/barlow/barlow-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/barlow/barlow-v12-latin-700.svg#Barlow') format('svg'); /* Legacy iOS */
}

/* barlow-condensed-600 - latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/barlow/barlow-condensed-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/barlow/barlow-condensed-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/barlow/barlow-condensed-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/barlow/barlow-condensed-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/barlow/barlow-condensed-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/barlow/barlow-condensed-v12-latin-600.svg#BarlowCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-mono-regular - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/roboto-mono/roboto-mono-v22-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}