.button {
    @apply inline-flex font-sans font-semibold no-underline antialiased transition-all duration-500 leading-none;
}

.hero-btn {
    @apply button text-primary-dark bg-white/70 border-2 border-secondary hover:text-white hover:bg-primary-dark items-center text-xl lg:text-2xl px-10 lg:px-16 py-3 font-semibold rounded-md uppercase tracking-wider justify-center flex;
}

/* Home page buttons */

.page-btn-primary {
    @apply button text-primary-dark bg-white/80 border-2 border-secondary hover:text-white hover:border-primary hover:bg-primary items-center text-lg  md:text-xl px-8 py-3 font-semibold rounded-md uppercase tracking-wider;
}

/* Interior page buttons */
.page-btn-secondary {
    @apply button text-primary/80  items-center text-lg py-0.5 font-semibold uppercase tracking-wider w-auto  
}

.menu-filter {
    @apply inline-flex no-underline transition-all duration-500 leading-none text-primary-dark bg-[#fff] border-[2px] border-accent/50 hover:text-white hover:border-primary hover:bg-primary items-center font-mono font-normal rounded-md subpixel-antialiased;
}