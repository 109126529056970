@import "fonts.css";
@import "typography";
@import "buttons";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h1, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

@layer utilities {
  .masonry-2-col {
      column-count: 2;
  }
  .break-inside {
      break-inside: avoid;
  }
}

@layer components {

  .gallery-display {
    @apply max-h-full max-w-full mx-auto shadow-xl border-8 border-white rounded;
  }

  .gallery-controls {
    @apply rounded-full font-mono tracking-tight text-base h-10 px-6 bg-primary-dark hover:bg-accent text-white flex items-center justify-center leading-none cursor-pointer;
  }

}

/** WP image alignments **/
@media screen and (min-width: 768px){
  .alignleft {
    float: left;
    max-width: 50%;
    margin-right: 2.5rem;
    margin-top: 0 !important;
  }

  .alignright {
    float: right;
    max-width: 50%;
    margin-left: 2.5rem;
    margin-top: 0 !important;
  }

  .aligncenter {
    margin: 1.5rem !important;
    margin-top: 0 !important;
  }
}
